import React from 'react';

import { SxProps, Typography } from '@mui/material';

import { remCalc } from 'theme/utils';

interface IInputLabelProps {
  label: string;
  sx?: SxProps;
  required?: boolean;
}

function InputLabel({ label, sx = {}, required = true }: IInputLabelProps): JSX.Element {
  return (
    <Typography
      color='#141414'
      variant='h6'
      sx={{
        width: 'auto',
        mb: '8px',
        fontSize: remCalc(14),
        lineHeight: '20px',
        fontWeight: 400,
        '&:first-letter': {
          textTransform: 'capitalize',
        },
        display: 'flex',
        gap: '8px',
        ...sx,
      }}
    >
      {label}
      {required && <Typography>*</Typography>}
    </Typography>
  );
}
InputLabel.defaultProps = {
  sx: {},
  required: true,
};
export default InputLabel;
