import React from 'react';

import {
  SxProps,
  TextField,
  Box,
  FormHelperText,
  StandardTextFieldProps,
  InputBaseProps,
} from '@mui/material';
import { FieldError, FieldValues, UseControllerProps, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ErrorMessage from '../error-message/ErrorMessage';
import InputLabel from '../input-label/InputLabel';

interface IExtraProps {
  errorLabel?: string;
}
interface CustomInputProps<T> extends UseControllerProps<T> {
  sx?: SxProps;
  label: string;
  helperText?: string[];
  helperTextTop?: string[];
  placeholder?: string | undefined;
  customError: FieldError | undefined;
  ns?: string[];
  translation?: boolean;
  type?: React.HTMLInputTypeAttribute;
  marginBottom?: string;
  inputProps?: StandardTextFieldProps;
  extraProps?: IExtraProps;
  className?: string;
  inputBaseProps?: InputBaseProps['inputProps'];
  required?: boolean;
}

function CustomInput<T extends FieldValues>({
  sx,
  label,
  helperText,
  control,
  name,
  customError,
  ns = [],
  translation,
  type,
  marginBottom,
  inputProps,
  extraProps,
  className,
  placeholder,
  helperTextTop = [],
  inputBaseProps,
  required = true,
}: CustomInputProps<T>): JSX.Element {
  const { t } = useTranslation([...ns, 'translation']);
  const { errorLabel = null } = extraProps ?? {};
  const customT = (key: string): string => (translation ? t(key) : key);

  const errorMessage = customError?.message
    ? t(customError?.message, {
        name: customT(errorLabel ?? label),
        ns: 'translation',
        type: t('validation.enter', { ns: 'translation' }),
      })
    : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): JSX.Element => {
        const { onBlur, onChange, ...rest } = field;
        return (
          <Box sx={{ mb: marginBottom }}>
            {label && <InputLabel label={customT(label)} required={required} />}
            {helperTextTop?.map((helper) => (
              <FormHelperText sx={{ mt: '8px', mb: '16px' }} key={helper}>
                {customT(helper)}
              </FormHelperText>
            ))}
            <TextField
              sx={{ minWidth: { xs: '300px', md: '400px' }, width: 'unset', ...sx }}
              {...rest}
              className={className}
              type={type}
              onChange={onChange}
              onBlur={(e): void => {
                e.target.value = e.target.value.trim();
                onChange(e);
                onBlur();
              }}
              error={!!customError}
              {...inputProps}
              placeholder={placeholder}
              inputProps={{
                'data-testid': field.name,
                ...inputBaseProps,
              }}
            />
            {helperText?.map((helper) => (
              <FormHelperText sx={{ mt: '8px' }} key={helper}>
                {customT(helper)}
              </FormHelperText>
            ))}
            {!!customError && <ErrorMessage message={errorMessage} />}
          </Box>
        );
      }}
    />
  );
}
CustomInput.defaultProps = {
  sx: {},
  placeholder: undefined,
  helperText: [],
  helperTextTop: [],
  ns: [],
  translation: true, // enable transition
  type: 'text',
  marginBottom: '16px',
  inputProps: {},
  extraProps: {},
  className: '',
  inputBaseProps: {},
  required: true,
};
export default CustomInput;
